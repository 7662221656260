import React from "react";
import Bredcom from "../Bredcrumb/Main";
import Book from "../../components/Menhome/Book";
import Img from "../../assets/images/WEBSITE PHOTOS/Different photos/steak-and-eggs-breakfast-with-toast-and-homestyle-2022-03-26-11-28-28-utc.jpg";

function Main() {
  return (
    <>
      <Bredcom subtitle="Reservation" title="BOOK YOUR TABLE" Img={Img} />

      <Book />
    </>
  );
}

export default Main;
