import React from "react";

import strength1 from "../../assets/images/Event & Party.png";
import strength2 from "../../assets/images/Fresh Environment.png";
import strength3 from "../../assets/images/Hygienic Food.png";
import strength4 from "../../assets/images/Skilled Chefs.png";

function Whyus() {
  return (
    <>
      <section className="why-us">
        <div className="auto-container">
          <div className="title-box centered">
            <div className="subtitle">
              <span>WHY CHOOSE US</span>
            </div>
            <div className="pattern-image">
              <img
                src={require("../../assets/images/icons/separator.svg").default}
                alt="mySvgImage"
              />
            </div>

            <h2>Our Strength</h2>
          </div>
          <div className="row clearfix">
            <div className="why-block col-xl-3 col-lg-6 col-md-6 col-sm-12">
              <div
                className="inner-box wow fadeInUp"
                data-wow-duration="1500ms"
                data-wow-delay="0ms"
              >
                <div className="icon-box">
                  <img src={strength3} alt="" />
                </div>
                <h4>Hygienic Food</h4>
                <div className="text">Feel The Magic of Hygienic Food</div>
              </div>
            </div>

            <div className="why-block col-xl-3 col-lg-6 col-md-6 col-sm-12">
              <div
                className="inner-box wow fadeInUp"
                data-wow-duration="1500ms"
                data-wow-delay="300ms"
              >
                <div className="icon-box">
                  <img src={strength2} alt="" />
                </div>
                <h4>Fresh Environment</h4>
                <div className="text">
                  Get Your Daily Dose of Fresh Environment
                </div>
              </div>
            </div>

            <div className="why-block col-xl-3 col-lg-6 col-md-6 col-sm-12">
              <div
                className="inner-box wow fadeInUp"
                data-wow-duration="1500ms"
                data-wow-delay="600ms"
              >
                <div className="icon-box">
                  <img src={strength4} alt="" />
                </div>
                <h4>Skilled Chefs</h4>
                <div className="text">
                  The Best Skilled Chefs Under One Roof
                </div>
              </div>
            </div>

            <div className="why-block col-xl-3 col-lg-6 col-md-6 col-sm-12">
              <div
                className="inner-box wow fadeInUp"
                data-wow-duration="1500ms"
                data-wow-delay="900ms"
              >
                <div className="icon-box">
                  <img src={strength1} alt="" />
                </div>

                <h4>Event & Party</h4>
                <div className="text">
                  Parties Will Be For You What You Want It To Be
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Whyus;
