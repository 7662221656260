import React from "react";
import Videosection from "../Menhome/Videosection";
import Contact from "../Menhome/Contact";
import Banner from "../Menhome/Banner";
import Offer from "../Menhome/Offer";
import Story from "../Menhome/Story";

import Whyus from "../Menhome/Whyus";

function Main() {
  return (
    <>
      <Banner />
      <Offer />
      <Story />

      <Contact />
      <Whyus />
      <Videosection />
    </>
  );
}

export default Main;
