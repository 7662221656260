import React from "react";
import { Route, Routes } from "react-router-dom";
import Header from "../components/Header/Main";
import Footer from "../components/Footer/Main";
import Home from "../components/Home/Main";
import About from "../components/About/Main";
import Confirmation from "../components/Confirmation/Main";
import Contact from "../components/Contact/Main";
import Menu from "../components/Menu/Main";

import Book from "../components/Book/Main";

import NotFound from "../components/NotFound/NotFound";

function Index() {
  return (
    <>
      <Header />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/menu" element={<Menu />} />

        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/reservation" element={<Book />} />

        <Route
          path="/reservation-confirmation/:reservationId"
          element={<Confirmation />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>

      <Footer />
    </>
  );
}

export default Index;
