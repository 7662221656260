import React from "react";
import Bredcom from "../Bredcrumb/Main";
import "./NotFound.css";
function NotFound() {
  return (
    <>
      <Bredcom subtitle="404" />
      <div className="notFound">
        <h2>Page Not Found</h2>
        <p className="sorryTxt">
          We're sorry, but the page you requested could not be found.
        </p>
        <a href="/">Go back to the homepage</a>
      </div>
    </>
  );
}

export default NotFound;
