import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useState } from "react";
import emailjs from "@emailjs/browser";

function Book() {
  const [persons, setPersons] = useState("1");
  const [availableHours, setAvailableHours] = useState([]);
  const [allHours, setAllHours] = useState([]);

  const [message, setMessage] = useState("");
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [reservationID, setReservationID] = useState("");

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const minDate = new Date().toISOString().slice(0, 10);

  const form = useRef();

  const getActualTime = () => {
    const now = new Date();
    const threeAM = new Date(now);
    threeAM.setHours(3, 0, 0, 0);
    if (now.getHours() >= 3) {
      threeAM.setDate(threeAM.getDate() + 1);
    }

    // Check if the time is between 12 am and 3 am
    if (now.getHours() >= 0 && now.getHours() < 3) {
      setAvailableHours("");
      return;
    }

    const hours = [];
    while (now < threeAM) {
      hours.push(now.getHours());
      now.setHours(now.getHours() + 1);
    }
    setAvailableHours(hours);
    setTime(hours[1]);
    return hours;
  };
  function getHours24() {
    const hours = [];
    for (let i = 6; i <= 24; i++) {
      hours.push(`${i < 10 ? "0" : ""}${i}:00`);
    }
    for (let i = 1; i <= 2; i++) {
      hours.push(`0${i}:00`);
    }
    setAllHours(hours);
    setTime(hours[1]);
    return hours;
  }

  function isDateToday(dateString) {
    const today = new Date();
    const date = new Date(dateString);
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  }

  function generateReservationId() {
    const timestamp = Date.now().toString().slice(-6); // get last 6 digits of current timestamp
    const randomNumber = Math.floor(Math.random() * 1000000)
      .toString()
      .padStart(6, "0"); // generate a random number between 0 and 999999 and pad it with leading zeros to ensure it has 6 digits
    const reservationId = timestamp + randomNumber; // combine timestamp and random number to create a unique ID
    setReservationID(reservationId);
  }

  const reservation = {
    name,
    phone,
    date,
    email,
    time,
    persons,
    reservationID,
    message,
  };
  const inputRef = useRef();

  const handlePersonsChange = (event) => {
    setPersons(event.target.value);
  };

  const handleTimeChange = (event) => {
    setTime(event.target.value);
  };

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };
  useEffect(() => {
    getActualTime();
    getHours24();
    generateReservationId();
    const dateInput = document.getElementById("myDateInput");

    // Check if the browser is a mobile browser
    const isMobile =
      /iPhone|iPad|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );

    // Add placeholder and remove dropdown arrow for mobile browsers
    if (isMobile) {
      dateInput.style.appearance = "none";
      dateInput.style.webkitAppearance = "none";
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/api/reservations`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reservation),
    })
      .then((response) => response.json())
      .then((data) => navigate(`/reservation-confirmation/${data._id}`))
      .then(() => setLoading(false))
      .catch((error) => console.error(error));
    emailjs.sendForm(
      process.env.REACT_APP_SERVICE2_ID,
      process.env.REACT_APP_TEMPLATE2_ID,
      form.current,
      process.env.REACT_APP_PUBLIC_KEY
    );
    emailjs.sendForm(
      process.env.REACT_APP_SERVICE_ID,
      process.env.REACT_APP_TEMPLATE3_ID,
      form.current,
      process.env.REACT_APP_PUBLIC_KEY
    );
  };

  return (
    <>
      <section className="online-reservation inner-page">
        <div className="auto-container">
          <div className="title-box centered">
            <div className="subtitle">
              <span>Reservation</span>
            </div>
            <div className="pattern-image">
              <img
                src={require("../../assets/images/icons/separator.svg").default}
                alt=""
                title=""
              />
            </div>

            <h2>Book A Table</h2>
            <div className="text request-info">
              Booking request <a href="tel:+39-064-875-321">+39-064-875-321</a>{" "}
              or fill out the order form
            </div>
          </div>
          <div className="default-form reservation-form">
            {loading && (
              <div className="loading-overlay">
                <div className="spinner-border text-warning" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
            <form ref={form} onSubmit={handleSubmit}>
              <div className="row clearfix">
                <div className="form-group col-lg-4 col-md-6 col-sm-12">
                  <div className="field-inner">
                    <input
                      className="l-icon datepicker"
                      type="text"
                      placeholder="Name"
                      name="name"
                      value={name}
                      required
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-group col-lg-4 col-md-6 col-sm-12">
                  <div className="field-inner">
                    <input
                      className="l-icon datepicker"
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={email}
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>

                <div className="form-group col-lg-4 col-md-6 col-sm-12">
                  <div className="field-inner">
                    <input
                      className="l-icon datepicker"
                      type="tel"
                      pattern="[0-9]+"
                      placeholder="Phone"
                      value={phone}
                      name="phone"
                      required
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-group col-lg-4 col-md-6 col-sm-12">
                  <div className="field-inner">
                    <span className="alt-icon far fa-user"></span>
                    <select
                      value={persons}
                      name="persons"
                      onChange={handlePersonsChange}
                      className="l-icon"
                      required
                    >
                      <option value="1">1 Person</option>
                      <option value="2">2 Persons</option>
                      <option value="3">3 Persons</option>
                      <option value="4">4 Persons</option>
                      <option value="5">5 Persons</option>
                      <option value="6">6 Persons</option>
                      <option value="7">7 Persons</option>
                      <option value="8">8 Persons</option>
                      <option value="9">9 Persons</option>
                      <option value="10">10 Persons</option>
                    </select>
                    <span className="arrow-icon far fa-angle-down"></span>
                  </div>
                </div>

                <div className="form-group col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="field-inner"
                    onClick={() => inputRef.current.showPicker()}
                  >
                    <span className="alt-icon far fa-calendar"></span>
                    <input
                      id="myDateInput"
                      ref={inputRef}
                      value={date}
                      onChange={handleDateChange}
                      className="l-icon datepicker"
                      type="text"
                      name="date"
                      min={minDate}
                      required
                      placeholder="Pick A Date"
                      onFocus={() => (inputRef.current.type = "date")}
                      onBlur={() => (inputRef.current.type = "text")}
                    />
                    <span className="arrow-icon far fa-angle-down"></span>
                  </div>
                </div>
                <div className="form-group col-lg-4 col-md-12 col-sm-12">
                  <div className="field-inner">
                    <span className="alt-icon far fa-clock"></span>
                    <select
                      className="l-icon"
                      value={time}
                      name="time"
                      onChange={handleTimeChange}
                    >
                      {isDateToday(date)
                        ? availableHours.length > 0
                          ? availableHours.slice(3).map((hour, i) => (
                              <option key={i} value={hour}>
                                {`${hour}:00`}
                              </option>
                            ))
                          : allHours.map((hour, i) => (
                              <option key={i} value={hour}>
                                {hour}
                              </option>
                            ))
                        : allHours.map((hour, i) => (
                            <option key={i} value={hour}>
                              {hour}
                            </option>
                          ))}
                    </select>
                    <span className="arrow-icon far fa-angle-down"></span>
                  </div>
                </div>
              </div>
              <div className="form-group col-lg-12 col-md-12 col-sm-12">
                <div className="field-inner">
                  <textarea
                    name="message"
                    placeholder="Message"
                    value={message}
                    onChange={handleMessageChange}
                    maxLength="150"
                  ></textarea>
                </div>
              </div>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 d-none">
                <div className="field-inner">
                  <input name="reservation_id" value={reservationID} />
                </div>
              </div>
              <div className="form-group col-lg-12 col-md-12 col-sm-12">
                <div className="field-inner">
                  <button
                    type="submit"
                    className="theme-btn btn-style-one clearfix"
                  >
                    <span className="btn-wrap">
                      <span className="text-one">book a table</span>
                      <span className="text-two">book a table</span>
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="map-location-section">
          <div className="auto-container">
            <div className="outer-box">
              <div className="row clearfix">
                <div className="reserv-col col-lg-8 col-md-12 col-sm-12">
                  <div className="inner">
                    <iframe
                      title="location"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2969.6833259417326!2d12.497508875984094!3d41.89966707123939!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132f61a46c6530c1%3A0xc639ea70f1032ee8!2sImperium%20Ristorante!5e0!3m2!1sen!2seg!4v1685007462239!5m2!1sen!2seg"
                      width="100%"
                      height="600"
                      style={{ border: 0 }}
                      allowFullScreen=""
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
                <div className="info-col col-lg-4 col-md-12 col-sm-12">
                  <div className="inner">
                    <div className="title">
                      <h2>Rome</h2>
                    </div>
                    <div className="data">
                      <ul className="info">
                        <li>
                          <strong>Contact Us</strong>
                          <br />
                          Location: via Giovanni Amendola,
                          <br /> 71, Rome Italy
                          <br /> Call : +39-064-875-321 <br /> Email :
                          booking@domainname.com{" "}
                        </li>
                        <div className="separator">
                          <span></span>
                        </div>
                        <li>
                          <strong>Open Daily</strong>
                          <br />
                          06:00 am - 03:00am
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Book;
