import React, { useState } from "react";
import { Link } from "react-router-dom";

import Imperium from "../../assets/images/LOGO WHITE.png";

function Main() {
  const [active, setActive] = useState();
  const [show, setShow] = useState();
  const [menu, setMenu] = useState();

  const [scrolled] = useState(false);
  const [Nav] = useState(true);

  return (
    <>
      {active && (
        <div
          className="menu-backdrop"
          style={{ opacity: "1", visibility: "visible" }}
          onClick={() => setActive(false)}
        ></div>
      )}

      <section
        className={`hidden-bar ${active && "visible-sidebar"}`}
        onClick={() => setActive(false)}
      >
        <div className="inner-box">
          <div
            className="cross-icon hidden-bar-closer"
            onClick={() => setActive(false)}
          >
            <span className="far fa-close"> </span>
          </div>
          <div className="logo-box">
            <Link to="/" title="Imperium">
              <img src={Imperium} alt="" title="Imperium" />
            </Link>
          </div>

          <div className="side-menu">
            <ul className="navigation clearfix">
              <li className=" dropdown">
                <Link
                  to="/"
                  onClick={() => setShow(show === true ? false : true)}
                >
                  HOME
                </Link>
              </li>
              <li className="dropdown">
                <Link
                  to="/menu"
                  onClick={() => setMenu(menu === true ? false : true)}
                >
                  MENU
                </Link>
              </li>
              <li>
                <Link to="/about">ABOUT US</Link>
              </li>

              <li>
                <Link to="/contact">CONTACT</Link>
              </li>
            </ul>
          </div>

          <h2>Visit Us</h2>
          <ul className="info">
            <li>
              Location: via Giovanni Amendola, <br />
              71, Rome Italy{" "}
            </li>
            <li>Open Daily: 06:00 am - 03:00 am</li>
            <li>
              <a href="mailto:reservation@ristoranteimperiumroma.com">
                reservation@ristoranteimperiumroma.com
              </a>
            </li>
          </ul>
          <div className="separator">
            <span></span>
          </div>
          <div className="booking-info">
            <div className="bk-title">Booking request</div>
            <div className="bk-no">
              <a href="tel:+39064875321">+39-064-875-321</a>
            </div>
          </div>
        </div>
      </section>
      {Nav && (
        <header
          className={`main-header ${scrolled && "fixed-header"} header-down`}
        >
          <div className="header-top">
            <div className="auto-container">
              <div className="d-flex justify-content-between align-items-center inner clearfix">
                <div className="top-left clearfix">
                  <ul className="top-info clearfix">
                    <li>
                      <i className="icon far fa-map-marker-alt"></i> Location:
                      via Giovanni Amendola, 71, Rome Italy
                    </li>
                    <li>
                      <i className="icon far fa-clock"></i> Open Daily: 06:00 am
                      - 03:00 am
                    </li>
                  </ul>
                </div>
                <div className="top-right clearfix">
                  <ul className="top-info clearfix">
                    <li>
                      <a href="tel:+39064875321">
                        <i className="icon far fa-phone"></i> +39-064-875-321{" "}
                      </a>{" "}
                    </li>
                    <li>
                      <a href="mailto:reservation@ristoranteimperiumroma.com">
                        <i className="icon far fa-envelope"></i>{" "}
                        reservation@ristoranteimperiumroma.com
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="header-upper header-fixed">
            <div className="auto-container">
              <div className="main-box d-flex justify-content-between align-items-center flex-wrap">
                <div className="links-box clearfix">
                  <div className="nav-toggler">
                    <button className="hidden-bar-opener">
                      <span
                        className="hamburger"
                        onClick={() => setActive(true)}
                      >
                        <span className="top-bun"></span>
                        <span className="meat"></span>
                        <span className="bottom-bun"></span>
                      </span>
                    </button>
                  </div>
                </div>

                <div className="logo-box">
                  <div className="logo">
                    <Link to="/" title="Imperium">
                      <img src={Imperium} alt="" title="Imperium" />
                    </Link>
                  </div>
                </div>

                <div className="linkContainer">
                  <div id="google_translate_element"></div>
                  <Link
                    to="/reservation"
                    className="theme-btn btn-style-one clearfix"
                  >
                    <span className="btn-wrap w-100">
                      <span className="text-one w-100">Book A Table</span>
                      <span className="text-two w-100">Book A Table</span>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </header>
      )}
    </>
  );
}

export default Main;
