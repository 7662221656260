import React from "react";
import { Link } from "react-router-dom";

import offerimg1 from "../../assets/images/WEBSITE PHOTOS/maindish.jpg";
import offerimg2 from "../../assets/images/WEBSITE PHOTOS/pizza.jpg";
import offerimg3 from "../../assets/images/WEBSITE PHOTOS/sweet.jpg";
import offerimg4 from "../../assets/images/WEBSITE PHOTOS/salad.jpg";

function Offer() {
  return (
    <>
      <section className="we-offer-section">
        <div className="auto-container">
          <div className="title-box centered">
            <div className="subtitle">
              <span>What We Offer ?</span>
            </div>

            <div className="pattern-image">
              <img
                src={require("../../assets/images/icons/separator.svg").default}
                alt="mySvgImage"
              />
            </div>

            <h2>
              <span>What We Offer ?</span>
            </h2>
            <div className="text">
              <span>
                Imperium offers a cuisine inspired by the local area, tradition
                and creativity in balanced
              </span>
            </div>
          </div>

          <div className="row justify-content-center clearfix">
            <div className="offer-block col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div
                className="inner-box wow fadeInUp"
                data-wow-duration="1500ms"
                data-wow-delay="0ms"
              >
                <div className="image">
                  <img src={offerimg2} alt="" />
                </div>
                <h3>
                  <p>Pizza</p>
                </h3>
                <div className="more-link">
                  <Link to="/menu#pizza">view menu</Link>
                </div>
              </div>
            </div>

            <div className="offer-block col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div
                className="inner-box wow fadeInUp"
                data-wow-duration="1500ms"
                data-wow-delay="300ms"
              >
                <div className="image">
                  <img src={offerimg1} alt="" />
                </div>
                <h3>
                  <p>main dishes</p>
                </h3>
                <div className="more-link">
                  <Link to="/menu#main">view menu</Link>
                </div>
              </div>
            </div>

            <div className="offer-block col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div
                className="inner-box wow fadeInUp"
                data-wow-duration="1500ms"
                data-wow-delay="600ms"
              >
                <div className="image">
                  <img src={offerimg4} alt="" />
                </div>
                <h3>
                  <p>Side dishes & Salads</p>
                </h3>
                <div className="more-link">
                  <Link to="/menu#side">view menu</Link>
                </div>
              </div>
            </div>

            <div className="offer-block col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div
                className="inner-box wow fadeInUp"
                data-wow-duration="1500ms"
                data-wow-delay="300ms"
              >
                <div className="image">
                  <img src={offerimg3} alt="" />
                </div>
                <h3>
                  <p>Drinks & Sweets</p>
                </h3>
                <div className="more-link">
                  <Link to="/menu#drinks">view menu</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Offer;
