import React from "react";
import Startermenu from "../Allmenus/FirstMenu/Startermenu";
import Bredcom from "../Bredcrumb/Main";
import Reservation from "../Allmenus/FirstMenu/Reservation";
import Img from "../../assets/images/WEBSITE PHOTOS/Different photos/set-of-asian-dishes-2021-12-09-20-31-02-utc.jpg";

function Main() {
  return (
    <>
      <Bredcom subtitle="Our Menu" title="DELICIOUS & AMAZING" Img={Img} />
      <Startermenu />
      <Reservation />
    </>
  );
}

export default Main;
