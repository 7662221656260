import React from "react";

import chef from "../../assets/images/WEBSITE PHOTOS/Different photos/young-chef-preparing-dough-for-pizza-2022-03-30-20-23-44-utc.JPG";
import about2 from "../../assets/images/about6.jpg";

function Story() {
  return (
    <>
      <section className="story-section">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="text-col col-xl-5 col-lg-5 col-md-12 col-sm-12">
              <div
                className="inner wow fadeInLeft"
                data-wow-duration="1500ms"
                data-wow-delay="0ms"
              >
                <div className="title-box centered">
                  <div className="subtitle">
                    <span>OUR STORY</span>
                  </div>
                  <div className="pattern-image">
                    <img
                      src={
                        require("../../assets/images/icons/separator.svg")
                          .default
                      }
                      alt="mySvgImage"
                    />
                  </div>
                  <h2>Every Flavor Tells a Story</h2>
                  <div className="text">
                    In addition to traditional European and italian cuisine, the
                    restaurant is already to offer you a lot of amazing
                    exclusive culinary masterpieces. Unique recipes in our menu
                    are developed by a team of chefs. You will find not only
                    exquisite dishes, high-quality wines, but also a pleasant
                    atmosphere.
                  </div>
                </div>
                <div className="booking-info">
                  <div className="bk-title">Book Through Call</div>
                  <div className="bk-no">
                    <a href="tel:+39064875321">+39-064-875-321</a>
                  </div>
                </div>
              </div>
            </div>

            <div className="image-col col-xl-7 col-lg-7 col-md-12 col-sm-12">
              <div
                className="inner wow fadeInRight"
                data-wow-duration="1500ms"
                data-wow-delay="0ms"
              >
                <div className="images parallax-scene-1">
                  <div className="image" data-depth="0.15">
                    <img className="storyImg" src={about2} alt="" />
                  </div>
                  <div className="image" data-depth="0.30">
                    <img src={chef} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Story;
