import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

function Confirmation() {
  const { reservationId } = useParams();
  const [reservation, setReservation] = useState({});

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_URL}/api/reservations/${reservationId}`,
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((data) => setReservation(data))
      .catch((error) => console.error(error));
  }, [reservationId]);
  return (
    <table className="confirmationTable">
      <thead>
        <tr>
          <th className="tableHeader">Reservation ID</th>
          <th className="tableHeader">Name</th>
          <th className="tableHeader">Email</th>
          <th className="tableHeader">Date</th>
          <th className="tableHeader">Time</th>
          <th className="tableHeader">Guests</th>
          <th className="tableHeader">Phone</th>
        </tr>
      </thead>
      <tbody>
        <tr key={reservation._id}>
          <td>{reservation?.reservationID}</td>
          <td>{reservation?.name}</td>
          <td>{reservation?.email}</td>
          <td>{reservation?.date?.substring(0, 10)}</td>
          <td>{reservation?.time}</td>
          <td>{reservation?.persons}</td>
          <td>{reservation?.phone}</td>
        </tr>
      </tbody>
    </table>
  );
}

export default Confirmation;
